<template>
    <ContentCard icon="assets/images/illu-poo.svg" theme="darkgreen">
        <template #title>WHOSE POO IS IT?</template>
        <template #description>Our cities are full of animals - discover why scientists are
            researching them and why they collect their poo!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'ihOsvw4XsmQ')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Match-A-Scat.pdf" target="_blank" icon="file" class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
