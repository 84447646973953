<template>
    <ContentCard theme="brown" icon="assets/images/illu-eucalyptus.svg">
        <template #title>DOES THIS TREE HAVE THE<br> RIGHT KOALA-FICATIONS?</template>
        <template #description>Follow Dr Eli on his wander through Sydney's west and learn about the
            tremendous Eucalyptus trees!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'u1OZngrSDvg')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Give-Me-Shelter.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
