<template>
    <ContentCard icon="assets/images/illu-tadpole.svg" theme="green">
        <template #title>WHO LIVES IN THE POND?</template>
        <template #description>
            There are only ducks in the pond? Think again and learn how you can find many more animals in our ponds!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'owp0BAoM39A')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Dipnetting.pdf" target="_blank" icon="file" class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
