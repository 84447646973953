<template>
    <ContentCard theme="navy" icon="assets/images/illu-boat.svg">
        <template #title>WHOSE BOAT IS THE FASTEST?</template>
        <template #description>Sydney to Hobart? You can join with your own rafts - follow our
            simple instructions to make your own boat!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'ISlgMr28l7o')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Raft-Craft.pdf" target="_blank" icon="file" class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
