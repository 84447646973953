<template>
    <ContentCard icon="assets/images/illu-ball.svg">
        <template #title>WHY DO BALLS BOUNCE?</template>
        <template #description>
            Playing ball in the park? Make sure there is no liquid nitrogen around… find out what
            would happen then!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'awuQVFOA5lE')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Battle-of-the-Bands.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
