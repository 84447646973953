<template>
    <ContentCard icon="assets/images/illu-coral.svg" theme="green">
        <template #title>WHY DOESN’T CORAL LIKE LEMON?</template>
        <template #description>Coral underwater gardens are beautiful but in real danger! Find out
            how you can help to save the underwater world.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'xq7217ZfwV8')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Acidifying-Coral.pdf?v=2" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
