<template>
    <ContentCard theme="navy" icon="assets/images/illu-leaf.svg">
        <template #title>MEET POISON IVY AND HER<br> FRIENDS!</template>
        <template #description>Animals defend themselves with poison - and so do plants! Learn about
            the toxic defences in our food!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'LHAyNqyJl0c')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Plant-Protectors.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
