

















import Vue from 'vue';

export default Vue.extend({
    data: () => ({}),
    props: {
        icon: {
            type: String,
            required: false,
            default: ''
        },
        theme: {
            type: String,
            required: false,
            default: '',
            validator(value: string): boolean {
                return ['', 'green', 'darkgreen', 'navy', 'brown'].indexOf(value) > -1;
            }
        }
    },
    computed: {
        iconSource() {
            return require(`@/${this.icon}`);
        }
    }
});
