








































import Vue from 'vue';
import CardFrogs from "../cards/CardFrogs.vue";
import CardRope from "../cards/CardRope.vue";
import CardBank from "../cards/CardBank.vue";


export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardFrogs,
        CardRope,
        CardBank,
    }
});
