<template>
    <ContentCard icon="assets/images/illu-ocean.svg" theme="green">
        <template #title>OCEAN LAB: DISCOVER OUR PLANKTON SUPERHEROES</template>
        <template #description>Meet plankton, the stars of our ocean and the pint-sized planet protectors that help save our seas.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'RIx1aFSL9j0')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Who-Eats-Who-Food-Chain-Activity.pdf" target="_blank"
                        icon="file" class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
