import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VueFullPage from 'vue-fullpage.js';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.min.css';

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper, {
    slidesPerView: 1,
    loop: true,
    autoHeight: true,
    pagination: {
        el: '.swiper-pagination'
    }
});

Vue.use(VueFullPage);

new Vue({
    store,
    render: h => h(App)
}).$mount('#app')
