<template>
    <ContentCard theme="navy" icon="assets/images/illu-bank.svg">
        <template #title>Why do plants need a bank?</template>
        <template #description>In the south of Sydney, you can find a bank that stores plants! Find
            out how and why they are stored there!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'JhzvBBozplI')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Jelly-Desserts.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
