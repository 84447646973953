<template>
    <ContentCard theme="navy" icon="assets/images/illu-koala.svg">
        <template #title>Who lives in the Scrub?<br> LET’S MEET THE ANIMALS!</template>
        <template #description>Western Sydney's Cumberland Plain Woodlands is full of animals - meet
            the furry, feathered, and the scaly ones.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'zv2H1YNr2Hs')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Eagle-Eye.pdf" target="_blank" icon="file" class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
