








































import Vue from 'vue';
import CardBallsBoounce from "../cards/CardBallsBoounce.vue";
import CardLost from "../cards/CardLost.vue";
import CardBees from "../cards/CardBees.vue";

export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardBallsBoounce,
        CardLost,
        CardBees,
    }
});
