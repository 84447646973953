































import Vue from 'vue';
import CardPoo from "../cards/CardPoo.vue";
import CardWeeds from "../cards/CardWeeds.vue";

export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardPoo,
        CardWeeds,
    }
});
