












































import Vue from 'vue';
import CardKangarooGrass from "../cards/CardKangarooGrass.vue";
import CardPoisonIvy from "../cards/CardPoisonIvy.vue";
import CardScrub from "../cards/CardScrub.vue";
import CardBoat from "../cards/CardBoat.vue";


export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardKangarooGrass,
        CardPoisonIvy,
        CardScrub,
        CardBoat,
    }
});
