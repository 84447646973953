<template>
    <ContentCard icon="assets/images/illu-berry.svg">
        <template #title>Lost in the Bush?<br> here’s what to eat!</template>
        <template #description>Follow Drew on his tour and learn how First Nations Australians have used plants for thousands of years.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', '_Tkjy4o7itc')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Purposeful-Plants.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
