































import Vue from 'vue';
import CardEucalyptus from "../cards/CardEucalyptus.vue";
import CardTree from "../cards/CardTree.vue";

export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardEucalyptus,
        CardTree,
    }
});
