<template>
    <ContentCard icon="assets/images/illu-dandelion.svg"
                 theme="darkgreen">
        <template #title>Which weeds can you eat?</template>
        <template #description>
            Diego will show you that not all weeds are bad - some are delicious! Find out which ones
            you can eat!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', '30pumzdhBEw')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Dandelion-Tea.pdf" target="_blank" icon="file" class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
