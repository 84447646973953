







































import Vue from 'vue';
import CardPond from "../cards/CardPond.vue";
import CardCoral from "../cards/CardCoral.vue";
import CardOceanLab from "../cards/CardOceanLab.vue";

export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardPond,
        CardCoral,
        CardOceanLab,
    }
});
